<template lang="pug">
div
  .csv-loader-wrapper(v-if="onLoadCsv")
    half-circle-spinner(
        :animation-duration="1000"
        color="#13b389"
        :size="60")
  Table(:showSelect="true"
            :columns="columsOrder"
            :createOption="createNewRole"
            :table="table"
            :showDeleteButton="true"
            :options="options"
            :showOnlyErrors="showOnlyErrors"
            :tableLength="tableLength"
            @on-remove-many-rows="onRemoveManyRows"
            @delete-row="deleteRow"
            @get-scroll-ref="putScrollRef")
    div(slot='head')
      slot(name="head")

    .action-buttons-setup-table(v-if="!hideImportAndAddEmployeeBtn" slot='add-row-button')
      div(style='margin-right:10px')
        button.btn.btn-clean-hover-primary.icon-btn(@click="openInput")
          i.fa.fa-upload
          | {{ 'pages.setup_wizard.import_from_csv'  | translate}}
        input(style="display: none" type='file' accept=".csv" ref="uploadCsv" @change='onFileUpload($event)')
        button.btn.btn-clean-hover-primary.icon-btn(@click="onAddEmployee")
          i.fa.fa-plus
          | {{ 'pages.setup_wizard.add_employee'  | translate}}

    .header-select-settings(v-if="!!recordsErrorCount" slot='error-toggler'  :style="selectErrorHeaderStyles"  v-show="showErorrButton" ref='errorToggler')
      div(v-if="showOnlyErrors")
        span {{ 'pages.setup_wizard.you_only_see_records'| translate }}
        strong  {{ recordsErrorCount }}
        span  {{ 'pages.setup_wizard._with_validation_errors' | translate }}. {{' '}}
        span.active-text( @click="errorTogler(false)")
          span {{ 'ui.labels.show_all' | translate }}
          strong  {{ tableLength }}
          span  {{ 'ui.labels._items' | translate }}.
      div(v-else)
        span {{ 'pages.setup_wizard.you_see_all_records' | translate }}.
        span  {{ 'ui.labels.show_only' | translate }}
        strong  {{ recordsErrorCount }}
        span  {{ 'pages.setup_wizard._records_with_validation_errors' | translate }}. {{' '}}
        span.active-text( @click="errorTogler(true)") {{ 'pages.setup_wizard.show_only_errors' | translate }}.

    div(slot='cancel')
      slot(name="cancel")
    div(slot='accept')
      slot(name="accept")

  colums-order-modal(
    v-if="showOrderModal"
    :show="showOrderModal"
    :csvColumns="csvColumns"
    :unrecognizedColumnsFromCsv="unrecognizedColumnsFromCsv"
    :unrecognizedStandartColumns="unrecognizedStandartColumns"
    :columsOrder="columsOrder"
    @on-save="saveInitOrder"
    @on-close="closeOrderModal")

  save-table-modal(
     v-if="showModals"
     :show="showModals"
     @on-close="closeStepModal"
     @on-save="nextInitStep")
</template>

<script>
import Papa from 'papaparse'
import { mapActions } from 'vuex'
import { HalfCircleSpinner } from 'epic-spinners'
import Table from '@/components/ui/Table/Table'
import uuidv1 from 'uuid/v1'
import ColumsOrderModal from './ColumsOrderModal'
import SaveTableModal from './SaveTableModal'
const jschardet = require('jschardet')

export default {
  name: 'TableWrapperCsvImport',

  components: {
    ColumsOrderModal,
    Table,
    SaveTableModal,
    HalfCircleSpinner
  },

  props: {
    columsOrder: {
      type: Array,
      default: () => []
    },
    table: {
      type: Array,
      default: () => []
    },
    csv: {
      default: null
    },
    options: Object,
    onSaveEvent: Function,
    onSaveTableSteps: {
      type: Function,
      default: () => {}
    },
    hideImportAndAddEmployeeBtn: {
      type: Boolean,
      default: false
    },
    tableLength: {
      type: Number,
      default: 0
    }

  },

  data: () => ({
    showOrderModal: false,
    showModals: false,
    csvColumns: [],
    unrecognizedStandartColumns: [],
    unrecognizedColumnsFromCsv: [],
    interimTable: [],
    showOnlyErrors: false,
    showErorrButton: false,
    onLoadCsv: false,
    scrollRef: {}

  }),

  async mounted () {
    this.onSaveEvent(this.onSaveTable)
    this.initTable()
  },

  methods: {
    ...mapActions('roles', [
      'loadRoles',
      'createRole'
    ]),

    ...mapActions('employees', [
      'loadPositions'
    ]),

    ...mapActions('administration', ['loadLocations', 'loadDepartments']),

    ...mapActions('setupWizard', ['setSrollPosY']),

    initTable () {
      if (!this.csv) return
      this.onLoadCsv = true

      const parseCsv = (csv, encoding) => {
        Papa.parse(csv, {
          encoding: encoding,
          skipEmptyLines: true,
          complete: (results) => {
            this.csvColumns = results.data.splice(0, 1)[0]
            this.unrecognizedStandartColumns = this.columsOrder.filter(item => {
              return !this.csvColumns.find((el, index) => el.trim().toLowerCase() === item.value.trim().toLowerCase())
            })

            this.unrecognizedColumnsFromCsv = this.csvColumns.filter((el, index) => {
              return !this.columsOrder.find(item => el.trim().toLowerCase() === item.value.trim().toLowerCase())
            }).map(el => {
              return { name: el, selected: {}, id: el }
            })

            this.csvColumns.forEach((el, index) => {
              this.columsOrder.forEach(item => {
                if (item.value.trim().toLowerCase() === el.trim().toLowerCase()) item.order = index
              })
            })

            this.interimTable = results.data

            if (this.unrecognizedStandartColumns.length) {
              this.showOrderModal = true
            } else {
              this.saveInitOrder()
            }
            this.onLoadCsv = false
          }
        })
      }
      const reader = new FileReader()

      reader.onload = (event) => {
        let res = event.target.result
        let detect = jschardet.detect(res, { minimumThreshold: 0 })
        parseCsv(this.csv, detect.encoding)
      }

      reader.readAsBinaryString(this.csv)
    },

    closeStepModal () {
      this.showModals = false
    },

    closeOrderModal () {
      this.interimTable = []
      this.$emit('on-close-order-modal')
      this.showOrderModal = false
    },

    onSaveTable () {
      this.showModals = true
      this.nextInitStep()
    },

    errorTogler (param = false) {
      this.$emit('is-error-mode', param)
      this.showOnlyErrors = param
    },

    async  nextInitStep () {
      this.showErorrButton = false

      let hasError = await this.onSaveTableSteps(this.interimTable)
      if (hasError) {
        this.showErorrButton = true
        this.errorTogler(true)
        // const { scrollTop } = this.scrollRef.getPosition()
        const scrollToErrorBlock = () => {
          // scrollTop - (Math.abs(this.$refs.errorToggler.getBoundingClientRect().y))
          this.scrollRef.scrollTo({
            y: 0
          },
          300,
          'easeInQuad'
          )
        }
        setTimeout(() => { scrollToErrorBlock() }, 0)
      }
      await this.closeStepModal()
      return true
    },

    saveInitOrder () {
      let interimOrder = [...this.columsOrder]
      interimOrder.sort((a, b) => b.order - a.order).sort((a, b) => a.hasOwnProperty('order') ? -1 : 1)
      let result = this.interimTable.map(item => {
        const obj = {}
        interimOrder.forEach((el, index) => {
          obj[el.key] = item[index]
          obj.selected = false
          obj.errors = []
          obj.hasError = false
          obj.id = uuidv1()
        })
        try {
          if (!isNaN(obj.active)) {
            obj.active = !!parseFloat(obj.active)
          } else {
            obj.active = !!obj.active
          }
        } catch (e) {
        }
        return obj
      })

      this.interimTable = result

      let filterForSelectors = this.columsOrder.filter(el => (el.type === 'select' || el.type === 'multiselect'))

      let options = { ...this.options }

      let createOptions = (key) => {
        this.interimTable.forEach(el => {
          if (key === 'role') {
            if (el[key]) {
              options[key] = [...options[key] || [], ...el[key].split(',').filter(el => !!el).map(item => { return { name: item.trim(), id: item.trim() } })]
            }
          } else {
            if (el[key]) {
              options[key] = [...options[key] || [], { name: el[key], id: el[key].trim() }]
            }
          }
        })
      }

      for (let k in filterForSelectors) {
        createOptions(filterForSelectors[k].key)
      }

      for (let key in options) {
        options[key] = [...new Map(options[key].map(item => [item.name, item])).values()]
      }

      let parseValues = (key) => {
        this.interimTable.forEach(el => {
          if (key === 'role') {
            if (!el[key]) {
              el[key] = []
            } else {
              let keys = el[key].split(',').filter(el => !!el)

              el[key] = options[key].map(item => {
                if (keys.some(element => element.trim() === item.name.trim())) return item
              }).filter(el => !!el)
            }
          } else {
            if (!el[key]) {
              el[key] = {}
            } else {
              el[key] = options[key].find(item => item.id.trim() === el[key].trim())
            }
          }
        })
      }

      for (let k in filterForSelectors) {
        parseValues(filterForSelectors[k].key)
      }
      this.showOrderModal = false
      this.$emit('on-update-options', options)
      this.$emit('on-update-table', this.interimTable)
    },

    onOptionsUpdate (options) {
      this.$emit('on-update-options', options)
    },

    onAddEmployee () {
      this.$emit('on-add-employee')
    },

    onFileUpload (event) {
      this.$emit('on-add-csv', event)
    },

    openInput () {
      this.$refs.uploadCsv.click()
    },

    deleteRow (id) {
      this.$emit('delete-row', id)
    },

    async createNewRole (roleName) {
      const result = await this.createRole(roleName)
      this.loadRoles()
      return result
    },

    onRemoveManyRows () {
      this.$emit('on-remove-many-rows')
    },

    putScrollRef (ref) {
      this.scrollRef = ref
    }
  },

  computed: {
    recordsErrorCount () {
      return this.table.filter(el => el.hasError).length
    },

    selectErrorHeaderStyles () {
      return this.showOnlyErrors ? 'background: #f4b146 ' : 'background: #f9cb81'
    }
  },

  watch: {
    csv: {
      handler (p) {
        if (p) {
          this.initTable()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.csv-loader-wrapper {
  position:absolute;
  top:48%;
  left:48%;
  z-index: 900;
  width: 100%;
  opacity: 0.6;
}

.action-buttons-setup-table{
  display: flex;
  justify-content: flex-end;
}

.header-select-settings {
  background:#f7f8fa;
  padding:1rem;
  text-align: center;
}

.active-text {
  font-size: 16px !important;
}
</style>
