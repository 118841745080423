<template lang="pug">
div(style="height:100%" ref="wrapper")
  div(v-if="column.type === 'text'")
    div.cell
      ui-text-edit(:placeholder="`ui.inputs.enter_${column.key}` | translate " :value="currentElement[column.key]"
                   :showPlaceholder="true"
                   @input='onChangeTextValue'
                   :inputTogglerOutside='true'
                   :truncateSize="23"
                   :showInputOutside="showTextEditInput"
                   @on-click-input="onClickCell")
      span.error-message(v-show="!!errorParser()" @click.stop) {{ errorParser() }}

  div.cell(v-else-if="column.type === 'multiselect' || column.type === 'select'" @click.stop)
    v-select(
            fixed
            name="select-for-table"
            v-model="currentElement[column.key]"
            :enableCreteOption='true'
            @on-open-dropdown-for-table="onOpenDropdownForTable"
            @on-open-dropdown="onOpenDropdown"
            :isForTable='true'
            ref='select'
            :single="column.type === 'select'"
            :horizontalMostBorders="tableHorizontalMostBorders"
            @on-create="onAddNewOption"
            :options="optionsParser"
            :placeholder="`ui.dropdown.select_${column.key}` | translate")
    span.error-message(v-show="!!errorParser()") {{ errorParser() }}

  div.cell.cell-switch(style="width:60px" v-else-if="column.type === 'switch'" @click.stop)
    span.kt-switch.kt-switch--sm.kt-switch--icon.kt-switch--primary.kt-switch--outline(style="display:inline")
      label(style="display:inline")
        input(type="checkbox" v-model="currentElement.active")
        span
</template>

<script>
import UiTextEdit from '@/components/ui/UiTextEdit'
export default {
  name: 'Cell',

  components: {
    UiTextEdit
  },

  mounted () {
    this.currentElement = this.element
  },

  props: {
    element: Object,
    type: String,
    ready: Boolean,
    selectedOption: Object,
    itemHeader: Object,
    column: Object,
    createOption: Function,
    options: Object,
    columnIndex: Number,
    tableHorizontalMostBorders: Object,
    index: Number,
    currentOpenCellInfo: {
      type: Object, default: () => {}
    },
    prevOpenCellInfo: {
      type: Object, default: () => {}
    }
  },

  data: () => ({
    currentElement: {},
    isUpdated: true,
    textAreaStyle: {
      left: 0,
      top: 0
    }
  }),

  methods: {
    onChangeTextValue (value) {
      this.currentElement[this.column.key] = value
    },

    onOpenDropdownForTable (value) {
      this.onClickCell(value)
    },

    onOpenDropdown (value) {
      this.$emit('on-open-dropdown', value)
    },

    onAddNewOption (name) {
      if (this.column.key === 'role') {
        const newRole = {
          id: name,
          name: name
        }
        this.currentElement[this.column.key].push(newRole)
        this.options[this.column.key].push(newRole)
      } else {
        const newRole = {
          id: name,
          name: name
        }
        this.currentElement[this.column.key] = newRole
        this.options[this.column.key].push(newRole)
      }

      this.$refs.select.closeDropdown()
    },

    onClickCell (param) {
      let currentOpenInputInfo = {}
      if (param) {
        currentOpenInputInfo = {
          rowId: this.element.id,
          cellIndexByRow: this.columnIndex
        }
      }
      this.$emit('on-open-text-edit-cell', currentOpenInputInfo)
    },

    errorParser () {
      let errorMessage = ''
      if (!this.element) return errorMessage
      if ((this.element.errors || []).length) {
        this.element.errors.forEach(element => {
          if (element.errors && element.errors.length && element.field === 'employee') {
            let data = element.errors.find(item => item.field === this.column.key)
            if (data && data.message) {
              errorMessage = data.message
            }
          } else if (element.errors && element.errors.length && element.field === this.column.key) {
            let data = element.errors[0]
            if (data && data.message) {
              errorMessage = data.message
            }
          }
        })
        return errorMessage
      }
      return errorMessage
    }
  },
  computed: {
    optionsParser () {
      return this.options[this.column.key]
    },

    showTextEditInput () {
      return this.currentOpenCellInfo.rowId === this.element.id && this.currentOpenCellInfo.cellIndexByRow === this.columnIndex
    }
  },

  watch: {
    currentOpenCellInfo: {
      handler (value) {
        if (this.$refs.select && this.currentOpenCellInfo.cellIndexByRow > 2 && this.currentOpenCellInfo.rowId === this.element.id && this.currentOpenCellInfo.cellIndexByRow === this.columnIndex) {
          this.$refs.select.openDropdown()
        }
      },
      deep: true
    }
  }

}
</script>

<style>
.text-edit-wrapper{
  display: flex;
  align-items: center;
  height: 40px;
}
</style>

<style lang="scss" scoped>
  .kt-switch input:empty {
    display: none;
  }

  .cell-switch {
    height: 100%;
    display: flex;
    align-items: center;
    height: 40px;
  }

  .cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-wrap: break-word;
    margin-left: 10px;
    width: 200px;
    min-height: 30px;

  }

  .error-message {
    font-size: 12px;
    color: red;
  }
</style>
