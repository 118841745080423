<template lang="pug">
div
  v-scrollable(
      id='scroll-panel'
      :bottom-offset="bottomOffset + 40"
      @get-scroll-ref="getScrollRef"
      )
    slot(name="head")
    div.head-panel-wrapper
      slot(name="add-row-button")
      rows-per-page(:showOnlyErrors="showOnlyErrors"
        :options="optionRowPerPage"
        :rowPerPage="rowPerPage"
        :table="table" :currentPage="currentPage"
        @on-update-per-page="updatePerPage"
        )
      slot(name="error-toggler")

      div.header-select-settings(v-if="selectedlength" :style="selectHeaderStyles")
        div(v-if="showHeaderSelectLabel")
          span {{ 'ui.labels.selected' | translate }}
          strong  {{ selectedlength }}
          span  {{ 'ui.labels._items' | translate }}. {{' '}}
          span.active-text(@click="onSelectAllItems")
            span {{ 'ui.labels.select_all' | translate }}
            strong  {{ table.length }}
            span  {{ 'ui.labels._items' | translate }}.
        div(v-else)
          span {{ 'ui.labels.all' | translate }}
          strong  {{ table.length }}
          span  {{ 'ui.labels._selected' | translate }}. {{' '}}
          span.active-text(@click="onCancelSelectAllItems") {{ 'ui.buttons.remove_selection' | translate }}

    div.table-wrapper(ref='table' :style="openDropdown && {'overflow':'hidden', 'padding-bottom':'6px'}")
      vue-scroll(:ops="ops" id='scroll-panel-horizontal')
        table.kt-table-striped.table
          Header(:rowPerPage="rowPerPage"
                      :selectedAll="selectedAll"
                      :pageCount="pageCount"
                      :selectedAllType="selectedAllType"
                      :showDeleteButton="showDeleteButton"
                      :columns="columns"
                      :showSelect="showSelect"
                      :currentPage="currentPage"
                      @sort-by="sortBy"
                      @on-select-all="onSelectAll"
                      )
          tbody
            tr(v-for="(item, index) in parsedTable"
                :key="item.id"
                :style="isHighlited(item)"
                @click='clickArea(item)')
              td(v-if="showSelect" class="checkbox-center-vertical-table")
                label(class="kt-checkbox" style="display:inline" @click.stop)
                  input(type="checkbox" v-model="item.selected" @change.stop='onChangeSelected')
                  span
              td(v-for="(itemHeader, columnIndex) in columns" :key="itemHeader.id")
                Cell(:element="item"
                    :tableHorizontalMostBorders="tableHorizontalMostBorders"
                    @on-open-dropdown='onOpenDropdown' :column="itemHeader"
                    :createOption="createOption"  :index="index" :options="options"  :columnIndex="columnIndex"
                    :currentOpenCellInfo="currentOpenCellInfo"
                    @on-open-text-edit-cell="onOpenTextEditCell"
                    :prevOpenCellInfo="prevOpenCellInfo")
              td(class="text-center table-actions-center")
                button.btn.btn-clean-hover-danger.btn-sm.btn-pill.btn-icon(
                  style="height:2.3rem"
                        v-if="showDeleteButton" @click="deleteRow(item.id)")
                  i(class="fa fa-trash-alt")

  div.table-footer-wrapper(ref='tableFooter')
    select-menu(:options="options" :table="table" @on-remove-many-rows="onRemoveManyRows")
    div.table-footer-actions
      div.table-footer-actions--btn-wrapper
        slot(name="cancel")
      div
        ui-pagination(v-if="showPagination" :page-count="pageCount" @on-page-change="onPageChange")
      div.table-footer-actions--btn-wrapper
        slot(name="accept")
</template>

<script>
import Cell from './Cell'
import Header from './Header'
import SelectMenu from './SelectMenu'
import RowsPerPage from './RowsPerPage'
import UiPagination from '@/components/ui/Pagination'

export default {
  name: 'Table',

  components: {
    Cell,
    Header,
    SelectMenu,
    UiPagination,
    RowsPerPage
  },

  props: {
    columns: {
      type: Array,
      default: () => []
    },
    table: {
      type: Array,
      default: () => []
    },

    showOnlyErrors: {
      type: Boolean,
      default: false
    },
    options: Object,
    showDeleteButton: Boolean,
    showSelect: Boolean,
    createOption: Function,
    tableLength: {
      type: Number,
      default: 0
    }
  },

  updated () {
    if (this.bottomOffset !== this.$refs.tableFooter.getBoundingClientRect().height) {
      this.bottomOffset = this.$refs.tableFooter.getBoundingClientRect().height
    }
  },

  mounted () {
    let bound = this.$refs.table.getBoundingClientRect()
    this.tableHorizontalMostBorders.left = bound.left
    this.tableHorizontalMostBorders.right = bound.left + bound.width

    window.onkeydown = (e) => {
      if (e.key === 'Tab') {
        this.onTabPress()
        return false
      }
    }
  },

  data: () => ({
    rowPerPage: {
      id: 2,
      count: 20,
      name: '20'
    },
    currentPage: 1,
    isSorting: false,

    selectedAllType: {},
    prevSelectedAllType: {},
    openDropdown: false,
    currentIselectedParam: false,
    allSelectedTypeByCurrentPage: true,
    tableHorizontalMostBorders: {
      left: 0,
      right: 0
    },
    currentOpenCellInfo: {},
    prevOpenCellInfo: {},
    ops: {
      scrollPanel: {
        scrollingY: false,
        scrollingX: true
      },
      rail: {
        disable: false,
        background: '#000',
        opacity: 0.1
      },
      bar: {
        disable: false,
        keepShow: true,
        opacity: 1
      }
    },
    bottomOffset: 40
  }),

  methods: {

    onTabPress () {
      const cellInfo = this.currentOpenCellInfo
      this.prevOpenCellInfo = { ...this.currentOpenCellInfo }
      if (Object.keys(cellInfo).length) {
        if (cellInfo.cellIndexByRow >= 6) {
          let index = this.parsedTable.findIndex(el => el.id === cellInfo.rowId)
          if (this.parsedTable[index + 1]) {
            cellInfo.rowId = this.parsedTable[index + 1].id
            cellInfo.cellIndexByRow = 0
          } else {
            this.currentOpenCellInfo = {}
          }
        } else {
          cellInfo.cellIndexByRow = cellInfo.cellIndexByRow + 1
        }
      }
    },

    onOpenTextEditCell (object) {
      this.currentOpenCellInfo = object
    },

    clickArea (item) {
      item.selected = !item.selected
      this.onChangeSelected()
    },

    deleteRow (index) {
      this.$emit('delete-row', index)
    },

    onOpenDropdown (value) {
      this.openDropdown = value
    },

    sortBy (param) {
      // sort for String
      if (this.isSorting) return null
      this.isSorting = true
      this.table.sort(function (a, b) {
        if (typeof a[param.key] === 'string') {
          if (a[param.key] > b[param.key]) {
            return param.sortIncrease ? 1 : -1
          }

          if (a[param.key] < b[param.key]) {
            return param.sortIncrease ? -1 : 1
          }
        }
        // sort for Object
        if (typeof a[param.key] === 'object') {
          if (a[param.key].name > b[param.key].name) {
            return param.sortIncrease ? 1 : -1
          }

          if (a[param.key].name < b[param.key].name) {
            return param.sortIncrease ? -1 : 1
          }
        }
        return 0
      })

      param.sortIncrease = !param.sortIncrease
      this.isSorting = false
    },

    onSelectAll (param = false) {
      let selected = !this.selectedAll
      if (this.allSelectedTypeByCurrentPage) {
        if (!selected) {
          this.parsedTable.forEach(el => { el.selected = false })
        } else {
          this.parsedTable.forEach(el => { el.selected = true })
        }
      } else {
        if (param) {
          this.table.forEach(el => { el.selected = true })
        } else {
          this.parsedTable.forEach(el => { el.selected = false })
        }
      }
    },

    onSelectAllItems () {
      this.allSelectedTypeByCurrentPage = false
      this.onSelectAll(true)
    },

    onCancelSelectAllItems () {
      this.allSelectedTypeByCurrentPage = true
      this.table.forEach(el => { el.selected = false })
    },

    onRemoveManyRows () {
      this.$emit('on-remove-many-rows')
    },

    onPageChange (page) {
      this.currentPage = page
    },

    updatePerPage (value) {
      this.rowPerPage = value
    },

    onChangeSelected () {
      this.allSelectedTypeByCurrentPage = true
    },

    isHighlited (item) {
      if (item.selected) return { background: '#e1f4ee !important' }
      else return {}
    },

    getScrollRef (ref) {
      this.$emit('get-scroll-ref', ref)
    }
  },

  computed: {
    showPagination () {
      return this.table.length > this.rowPerPage.count
    },

    pageCount () {
      return Math.ceil(this.table.length / this.rowPerPage.count)
    },

    parsedTable () {
      return this.table.slice((this.rowPerPage.count * this.currentPage - this.rowPerPage.count), this.rowPerPage.count * this.currentPage)
    },

    optionRowPerPage () {
      return [
        { id: 1, count: 10, name: '10' },
        { id: 2, count: 20, name: '20' },
        { id: 3, count: 50, name: '50' },
        { id: 4, count: 75, name: '75' },
        { id: 5, count: this.table.length, name: this.$t('ui.labels.all') }
      ]
    },

    selectedAll () {
      return !!this.parsedTable.length && this.parsedTable.every(el => el.selected)
    },

    selectedlength () {
      return this.table.filter(el => !!el.selected).length
    },

    showHeaderSelectLabel () {
      return this.allSelectedTypeByCurrentPage && this.table.length > this.selectedlength
    },

    selectHeaderStyles () {
      return this.showHeaderSelectLabel ? '' : 'background: #e0e1e2'
    }

  }
}

</script>
<style lang="scss">
.table-wrapper {
  .__panel{
    margin-bottom: -15px !important;
    padding-right: 15px !important;
    margin-right: 0px !important;
  }
  .__rail-is-horizontal{
    bottom: -6px !important;
    margin:0 2rem;
  }
}
</style>
<style lang="scss" scoped>
.select {
  width:200px
}

.table-footer-wrapper{
   padding:1rem 2rem 2rem;

  .table-footer-actions{
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--btn-wrapper{
      display: flex;
      justify-content: flex-end;
      min-width: 150px;
    }
  }
}

.head-panel-wrapper{
  padding-right: calc(2rem - 15px ) ;
  padding-left: 2rem;
}

.table-wrapper {
  width: 100%;
  z-index: 1;
  padding-right: calc(2rem - 15px ) ;
  padding-left: 2rem;
}

tr {
  display: flex;
  justify-content: space-between;

  .table-actions-center{
    padding-left: 0px !important;
    padding-right: 1.5rem !important;
     padding-top: calc(1.5rem) !important
  }

   td{
     height: 100%;
   }
}

.table td {
  border-top: 0px;
  padding: 1rem;
}
tr:nth-child(odd) {
  background:#f7f8fa !important;
}

.link-text-selected {
  text-decoration: underline;
  color:rgb(19, 179, 137);
  cursor: pointer;
}

.kt-checkbox {
  padding-left: 0px;
}

.checkbox-center-vertical-table{
  width:60px;
  display: flex;
  justify-content: center;
  padding-top: calc(1.7rem) !important
}

.header-select-settings {
  background:#f7f8fa;
  padding:1rem;
  text-align: center;
}
</style>
