<template lang="pug">
  ui-modal(
      :title="$t('pages.setup_wizard.select_unrecognized_columns')"
      :show="show"
      @on-close="onClose")
    div.direction-column(slot='body')
        div.unselected-options-rows(v-for="(el, index) in unrecognizedColumnsFromCsv" :key="index + 'i'")
              div.unselected-options-element
                span {{ el.name }}
              v-select(
                      name='rows'
                      fixed
                      single
                      trackBy='value'
                      :placeholder="'ui.dropdown.select_column' | translate"
                      :options="unrecognizedStandartColumns"
                      :value="el.selected"
                      @input="onSelect($event, index)")
        p.invalid(v-show="isMatchColunmError") {{ 'pages.setup_wizard.you_must_select_all_variants_and_columns_should_not_be_repeated'  | translate}}
    v-btn(
        slot="footer"
        @click="saveChanges") {{ 'ui.buttons.save'  | translate}}
</template>
<script>

import UiModal from '@/components/ui/Modal'

export default {
  name: 'ColumsOrderModal',

  components: {
    UiModal
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    csvColumns: Array,
    unrecognizedColumnsFromCsv: Array,
    unrecognizedStandartColumns: Array,
    columsOrder: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    selected: [],
    isMatchColunmError: false,
    openDopdown: false,
    currentOpenIndex: null
  }),

  methods: {
    onClose () {
      this.$emit('on-close')
    },

    onSave () {
      this.$emit('on-save')
    },

    onSelect (item, index) {
      this.unrecognizedColumnsFromCsv[index].selected = item
      let i = this.csvColumns.findIndex(el => el === this.unrecognizedColumnsFromCsv[index].name)
      let findEl = this.columsOrder.find(el => el.id === item.id)
      if (findEl) findEl.order = i
    },

    saveChanges () {
      this.isMatchColunmError = false
      let unrecognizedColumnsFromCsv = [...this.unrecognizedColumnsFromCsv]

      if (unrecognizedColumnsFromCsv.find(el => !!el.selected && !el.selected.value)) {
        this.isMatchColunmError = true
        return null
      }

      if (this.unrecognizedStandartColumns.map(item => {
        return unrecognizedColumnsFromCsv.filter(el => item.value.trim().toLowerCase() === el.selected.value.trim().toLowerCase()).length
      }).some(num => num > 1)) {
        this.isMatchColunmError = true
        return null
      }

      this.onSave()
    }

  },

  computed: {
    isActiveEl () {
      return (index) => this.currentOpenIndex === index
    }
  }
}
</script>

<style lang="scss" scoped>

  .direction-column {
    width: 100%;
    padding: 0 15px;
  }

  .unselected-options-element {
    position: relative;
    display: inline-block;
    width: 150px;
    margin: 8px 0;
    margin-right: 15px;
      span {
        word-break: break-word;
      }
  }

    .unselected-options-rows {
      display: flex;
      flex-direction: row;
      margin: 10px 0;

  }

</style>
