<template lang="pug">
  div.row-dropdowns-select-menu
    v-select(
      style="width:200px"
      fixed
      name='action'
      label="name"
      single
      :placeholder="'ui.inputs.choose_action' | translate"
      :options="actionOptions"
      v-model="selectedAction"
    )

    v-select.roles-dropdown(
      style="width:200px"
      fixed
      v-if="showRole"
      name="roles"
      v-model="isSelectedRoles"
      :options="setOptions"
      :ready="isUpdated"
    )

    v-select.roles-dropdown(
      style="width:200px"
      fixed
      v-if="showAnyOptions"
      class='form-group filter-select'
      :clearable="false"
      label='name'
      single
      :options="setOptions"
      v-model="selectedOption")

    button.dropbtn(
      v-show="showApply"
      @click.prevent="applyChanges")
      | {{ 'ui.buttons.apply' | translate }}

</template>

<script>
import uuidv1 from 'uuid/v1'

export default {
  name: 'SelectMenu',

  props: {
    table: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    isOpenroles: false,
    showDropdown: false,
    showRoleDropdown: false,
    isSelectedRoles: [],
    selectedAction: {},
    isUpdated: true,
    selectedOption: {}
  }),

  methods: {
    applyChanges () {
      if (this.selectedAction.key === 'remove') {
        this.remove()
      } else if (this.selectedAction.key === 'make_status_active') {
        this.makeStatus(true)
      } else if (this.selectedAction.key === 'make_status_inactive') {
        this.makeStatus(false)
      } else if (this.selectedAction.key === 'set_role') {
        this.setRole()
      } else {
        this.onSelectAnyOption()
      }
    },

    setRole () {
      const newRoles = JSON.parse(JSON.stringify(this.isSelectedRoles))
      this.table.forEach(el => {
        if (el.selected) el.role = newRoles
      })
      this.isSelectedRoles = []
      this.selectedAction = {}
    },

    makeStatus (param) {
      this.selectedAction = {}
      this.table.forEach(el => {
        if (el.selected) el.active = param
      })
    },

    remove () {
      this.selectedAction = {}
      this.$emit('on-remove-many-rows')
    },

    onSelectAnyOption () {
      const key = this.selectedAction.key.split('_')[1]
      this.table.forEach(el => {
        if (el.selected) el[key] = this.selectedOption
      })
      this.selectedOption = {}
      this.selectedAction = {}
    }
  },

  computed: {
    actionOptions () {
      return [
        { key: 'remove', name: this.$t('ui.buttons.remove'), id: uuidv1() },
        { key: 'make_status_active', name: this.$t('ui.buttons.make_active'), id: uuidv1() },
        { key: 'make_status_inactive', name: this.$t('ui.buttons.make_inactive'), id: uuidv1() },
        { key: 'set_position', name: this.$t('ui.buttons.set_position'), id: uuidv1() },
        { key: 'set_department', name: this.$t('ui.buttons.set_department'), id: uuidv1() },
        { key: 'set_location', name: this.$t('ui.buttons.set_location'), id: uuidv1() },
        { key: 'set_role', name: this.$t('ui.buttons.set_role'), id: uuidv1() }
      ]
    },

    showApply () {
      return !!this.selectedAction.key
    },

    showRole () {
      return !!this.selectedAction.key && this.selectedAction.key === 'set_role'
    },

    showAnyOptions () {
      return !!this.selectedAction.key && (this.selectedAction.key.includes('set_location') || this.selectedAction.key.includes('set_position') ||
              this.selectedAction.key.includes('set_department'))
    },

    setOptions () {
      return this.options[this.selectedAction.key.split('_')[1]]
    }
  }
}
</script>

<style lang="scss" scoped>

.dropbtn {
  background-color: $light-blue;
  border: none;
  height: 40px;
  min-width: 150px;
  font-size: 16px;
  font-weight: 600;
  color: #646c9a;
  padding: 0 20px;
  margin-left: 40px;
  cursor: pointer;
}

.dropbtn[disabled] {
  background-color: #f1f1f1;
}

.row-dropdowns-select-menu {
  margin-top:20px;
  display: flex;
  flex-direction:row

}

.roles-dropdown {
  margin-left: 40px;
  width:200px
}

.select {
  width:200px
}
</style>
