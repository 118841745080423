<template lang="pug">
.table-settings-dropdowns
  div
    span(v-if="table.length") {{ renderText }}
  div.row
    span.title-row-per-page {{ 'pages.setup_wizard.rows_per_page' | translate}}
    div.select
      v-select(
          name='rows'
          single
          fixed
          :options="options"
          :value="rowPerPage"
          @input="onChange")
</template>

<script>
export default {
  name: 'RowsPerPage',

  props: {
    options: {
      type: Array,
      default: () => []
    },
    rowPerPage: {
      type: Object,
      default: () => {}
    },
    table: {
      type: Array,
      default: () => []
    },
    currentPage: Number,
    showOnlyErrors: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    prevSelected: {}
  }),

  methods: {
    onChange (value) {
      if (!Object.keys(value).length) {
        value = this.prevSelected
      }
      this.$emit('on-update-per-page', value)
      this.prevSelected = { ...value }
    }
  },

  computed: {
    renderText () {
      return ((this.rowPerPage.count * this.currentPage - this.rowPerPage.count) + 1) + ' - ' + (this.rowPerPage.count * this.currentPage < this.table.length ? this.rowPerPage.count * this.currentPage : this.table.length) +
        ` ${this.$t('ui.labels._of')}  ` + (this.showOnlyErrors ? this.table.filter(el => el.hasError).length : this.table.length)
    }
  }

}
</script>

<style lang="scss" scoped>

.row {
  padding-right:8px ;
  display: flex;
  flex-direction:row;
  justify-content: flex-end;
  height: 40px;
  text-align: center;
  .title-row-per-page  {
    padding:8px 10px;

  }
}

.select {
width:100px
}

.table-settings-dropdowns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin:20px 0 10px 0;
}
</style>
