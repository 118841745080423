<template lang="pug">
  transition(name="fade-up")
    .overlay(style="z-index:99")
      .modal-dialog.modal-position.max-width
        .modal-content
          div.center-last-modal-options
            span.loading-modal-text {{'pages.setup_wizard.please_wait_we_are_finishing_setup' | translate}}
            half-circle-spinner(
              :animation-duration="1000"
              color="#13b389"
              :size="60")
</template>
<script>
import { HalfCircleSpinner } from 'epic-spinners'

export default {
  name: 'SaveTableModal',

  components: {
    HalfCircleSpinner
  },

  methods: {
    onSave () {
      this.$emit('on-save')
    },

    async saveChanges () {
      await this.onSave()
    }
  }

}
</script>

<style lang="scss" scoped>

.center-last-modal-options{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:500px;
  height: 400px;
  overflow: hidden;
  position: relative;
  padding-top: 10px;

  .loading-modal-text{
    margin-bottom: 20px;
  }

}
</style>
