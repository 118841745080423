<template lang="pug">
  thead
    tr
      th(v-if="showSelect" style='width:60px')
        label.kt-checkbox.checkbox-wrap-table-header(style="display:inline")
          input(type="checkbox" @change='onSelect' :checked="selectedAll")
          span
      th(v-for="(itemHeader,index) in columns" :key='index')
        span.hover-arrow(:style="itemHeader.style || ''" @click="sortBy(itemHeader)") {{ itemHeader.value }}
          i(v-if="itemHeader.sorted"  class="arrows" :class="itemHeader.sortIncrease ? 'flaticon2-arrow-up' :'flaticon2-arrow-down'")
      th(style='width: calc(60px - 1rem)' v-if="showDeleteButton")
        span(style='opacity:0')
</template>

<script>

export default {
  name: 'Header',

  props: {
    showSelect: Boolean,
    columns: {
      type: Array,
      default: () => []
    },
    showDeleteButton: Boolean,
    selectedAllType: Object,
    currentPage: Number,
    pageCount: Number,
    rowPerPage: Object,
    selectedAll: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    sortBy (param) {
      this.$emit('sort-by', param)
    },

    onSelect () {
      this.$emit('on-select-all')
    }
  }
}
</script>

<style lang="scss" scoped>
.arrows {
  opacity: 0;
  font-size:9px;
  transition: opacity 0.3s linear;
}

tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
   align-items: center;
   border-bottom: 2px solid #ebedf2;
}

.table thead th{
   border-bottom: none;
  padding: 1rem;
  position: relative;
}
.hover-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  margin-left:10px;
}
.hover-arrow:hover {
  .arrows{
    padding-left:2px ;
    opacity: 1
  }
}
.kt-checkbox{
  padding-left: 0px;
}
.checkbox-wrap-table-header {
  margin-left: 18px;
}
</style>
