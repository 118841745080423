<template lang="pug">
    .text-edit-wrapper(:class="{ 'blank-text' : showPlaceholderValue}" @click.stop="doEdit(true)" )
      span.text-edit(ref='textCell'  v-if="!showInputParser && !hasTextSlot ")
        | {{ showPlaceholderValue ? placeholder  : value | translate | truncateText(truncateSize) }}
      slot(name='text' v-else-if="hasTextSlot && !showInputParser")
      input.text-edit-input(v-else v-focus  v-click-outside="()=>{doEdit(false)}" @keyup.enter="doEdit(false)" :value="value" @input='onChangeValue')
      i.fa.fa-pencil-alt(v-if="!this.disabled && !showInputParser  && showIcons" @click.stop="doEdit(true)")
      i.fa.fa-check(v-else-if="!this.disabled && showIcons" @click.stop="doEdit(false)")
</template>

<script>
export default {
  name: 'UiTextEdit',

  props: {
    value: String,
    showPlaceholder: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    truncateSize: {
      type: Number,
      default: 0
    },
    inputTogglerOutside: {
      type: Boolean,
      default: false
    },
    showInputOutside: {
      type: Boolean,
      default: false
    },
    showIcons: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }

  },

  data: () => ({
    showInput: false
  }),

  methods: {
    doEdit (param = false) {
      if (!this.disabled) {
        if (this.inputTogglerOutside) {
          this.$emit('on-click-input', param)
        } else {
          this.showInput = param
        }
      }
    },

    onChangeValue (e) {
      this.$emit('input', e.target.value)
    }
  },

  computed: {
    showPlaceholderValue () {
      return this.showPlaceholder && !this.value
    },

    showInputParser () {
      if (this.inputTogglerOutside) {
        return this.showInputOutside
      } else {
        return this.showInput
      }
    },

    hasTextSlot () {
      return !!this.$slots.text
    }
  }
}
</script>

<style lang="scss" scoped>
.text-edit-wrapper{
  display: flex;
  width: 100%;

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-black;
    margin-left: 10px;
    cursor: pointer;

    &:hover{
      color:$color-primary
    }
  }

  .text-edit{
    display: inline-block;
    width: fit-content;
    padding: 8px 0px;
  }

  .text-edit-input{
    overflow:auto;
    z-index: 101;
    width: 100%;
    padding:0;
    font-size: 16px;
    border: none;
    color: #212529;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);
    font-weight: 300;
  }
}

.blank-text {
    color:grey
}

</style>
